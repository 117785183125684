import { createFeature, createReducer, on } from '@ngrx/store';
import { Commission } from 'src/app/shared/models';
import { PayoutActions } from './payout.actions';

export const payoutFeatureKey = 'payout';

export interface PayoutState {
  payouts: Commission[];
  payout: Commission | null;
  loading: boolean;
  error: Error | null;
}

export const initialState: PayoutState = {
  payouts: [],
  payout: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    PayoutActions.loadPayouts,
    (state): PayoutState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    PayoutActions.loadPayoutsSuccess,
    (state, { payouts }): PayoutState => ({
      ...state,
      payouts: [
        ...state.payouts.filter(
          (item) => !payouts.find((x) => x.id === item.id),
        ),
        ...payouts,
      ],
      loading: false,
      error: null,
    }),
  ),
  on(
    PayoutActions.loadPayoutsFailure,
    (state, { error }): PayoutState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    PayoutActions.loadPayout,
    (state): PayoutState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    PayoutActions.loadPayoutSuccess,
    (state, { payout }): PayoutState => ({
      ...state,
      payouts: [
        ...state.payouts.filter((item) => payout.id !== item.id),
        payout,
      ],
      payout: payout,
      loading: false,
      error: null,
    }),
  ),
  on(
    PayoutActions.loadPayoutFailure,
    (state, { error }): PayoutState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    PayoutActions.addPayout,
    (state): PayoutState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    PayoutActions.addPayoutSuccess,
    (state, { payout }): PayoutState => ({
      ...state,
      payouts: [...state.payouts, payout],
      payout: payout,
      loading: false,

      error: null,
    }),
  ),
  on(
    PayoutActions.addPayoutFailure,
    (state, { error }): PayoutState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    PayoutActions.removePayout,
    (state): PayoutState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    PayoutActions.removePayoutSuccess,
    (state, { payoutId }): PayoutState => ({
      ...state,
      payouts: state.payouts.filter((payout) => payout.id !== payoutId),
      loading: false,
      error: null,
    }),
  ),
  on(
    PayoutActions.removePayoutFailure,
    (state, { error }): PayoutState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    PayoutActions.updatePayout,
    (state): PayoutState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    PayoutActions.updatePayoutSuccess,
    (state, { payout }): PayoutState => ({
      ...state,
      payouts: state.payouts.map((item) =>
        // item.id === commission.id ? commission : item
        item.id === payout.id ? { ...item, ...payout } : item,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    PayoutActions.updatePayoutFailure,
    (state, { error }): PayoutState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const payoutFeature = createFeature({
  name: payoutFeatureKey,
  reducer,
});
