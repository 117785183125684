import { createActionGroup, props } from '@ngrx/store';
import { Commission } from 'src/app/shared/models';

export const PayoutActions = createActionGroup({
  source: 'Payout',
  events: {
    'Load Payouts': props<{ agencyId: string }>(),
    'Load Payouts Success': props<{ payouts: Commission[] }>(),
    'Load Payouts Failure': props<{ error: any }>(),
    'Load Payout': props<{ payoutId: string; agencyId: string }>(),
    'Load Payout Success': props<{ payout: Commission }>(),
    'Load Payout Failure': props<{ error: any }>(),
    'Add Payout': props<{ payout: Commission; agencyId: string }>(),
    'Add Payout Success': props<{ payout: Commission; agencyId: string }>(),
    'Add Payout Failure': props<{ error: any }>(),
    'Remove Payout': props<{ payoutId: string; agencyId: string }>(),
    'Remove Payout Success': props<{ payoutId: string; agencyId: string }>(),
    'Remove Payout Failure': props<{ error: any }>(),
    'Update Payout': props<{
      payoutId: string;
      agencyId: string;
      payout: Partial<Commission>;
    }>(),
    'Update Payout Success': props<{
      payoutId: string;
      agencyId: string;
      payout: Partial<Commission>;
    }>(),
    'Update Payout Failure': props<{ error: any }>(),
  },
});
